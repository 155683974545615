import React from 'react';
import { Button } from '@material-ui/core';

import { CheckMark } from '../../utility/Icons';

import styles from '../../../styles/sass/components/SuccessDialog.module.scss';

interface Props {
  dialogText: string;
  handleClose: (e: any) => void;
}

export default class Content extends React.Component<Props> {
  render() {
    return (
      <div className={styles.dialogContainer}>
        <img
          className={styles.mobileWaveIcon}
          data-alt="mobile-wave"
          src="../../../static/img/mobile_modal_wave.png"
        />
        <h1 className={styles.successTitle}>Success!</h1>
        <div className={styles.successIconContainer}>
          <CheckMark iconStyle={{ width: 100, height: 76 }} />
          <p className={styles.successInfo}>{this.props.dialogText}</p>
        </div>

        <div className={styles.successButtonContainer}>
          <Button
            variant="contained"
            size="medium"
            style={{
              backgroundColor: '#2dc7a4',
              color: '#fff',
              fontSize: 18,
              maxWidth: 275,
              width: '100%',
            }}
            onClick={this.props.handleClose}
          >
            DONE
          </Button>
        </div>
      </div>
    );
  }
}
