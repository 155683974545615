import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as types from '../../../actions/actionTypes';
import ResponsiveDialog from '../../utility/ResponsiveDialog';
import Content from './Content';

import styles from '../../../styles/sass/components/SuccessDialog.module.scss';

export interface Props {
  dialogText: string;
  dispatch: any;
  open: boolean;
}

export class ResponsiveSuccessDialog extends React.Component<Props> {
  handleClose = () => {
    if (this.props.open) {
      this.props.dispatch({ type: types.CloseSuccessDialog });
    }
  };

  render() {
    const { open, dialogText } = this.props;
    return (
      <ResponsiveDialog
        onClose={this.handleClose}
        open={open}
        className={styles.successfulDialog}
        buttonClassName={styles.dialogCloseButton}
      >
        <Content dialogText={dialogText} handleClose={this.handleClose} />
      </ResponsiveDialog>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    dialogText: state.dialogs.successDialogText,
    open: state.dialogs.successDialogOpen,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResponsiveSuccessDialog);
